import { Injectable } from '@angular/core'
import { HttpClient } from '@angular/common/http'
import { ServiceInvokerService } from '../commons/service-invoker.service'
import { Observable } from 'rxjs'
import { AppSettings } from '../constants/app-setting'
import { Api } from '../constants/api'
import { LOG_ACTION, LOG_OBJECT } from '../constants/CONSTANTS'

// import { retry } from 'rxjs/operators';
// import { request } from 'http';
import { UserInfoStorageService } from '../commons/user-info-storage.service'

@Injectable({
  providedIn: 'root',
})
export class LaiThuongService {
  constructor(private http: HttpClient, private serviceInvoker: ServiceInvokerService, private localStronageService: UserInfoStorageService) { }


  CreateRutLai(request: any): Observable<any> {
    let headers = {
      logAction: encodeURIComponent(LOG_ACTION.INSERT),
      logObject: encodeURIComponent(LOG_OBJECT.NDT_RUTLAI + ": " + request.name),
    }
    return this.serviceInvoker.postwithHeaders(request, AppSettings.API_ADDRESS + Api.CreateRutLai, headers)
  }
  DashboardGetSoTien(request: any): Observable<any> {
    let headers = {
      logAction: encodeURIComponent(LOG_ACTION.GETLIST),
      logObject: encodeURIComponent(LOG_OBJECT.NDT_DAUTU),
    }
    return this.serviceInvoker.getwithHeaders(request, AppSettings.API_ADDRESS + Api.DashboardGetSoTien, headers)
  }
  LaiThuongGetSoTien(request: any): Observable<any> {
    let headers = {
      logAction: encodeURIComponent(LOG_ACTION.GETLIST),
      logObject: encodeURIComponent(LOG_OBJECT.NDT_DAUTU),
    }
    return this.serviceInvoker.getwithHeaders(request, AppSettings.API_ADDRESS + Api.LaiThuongGetSoTien, headers)
  }
 
  LaiThuongGetListByUser(request: any): Observable<any> {
    let headers = {
      logAction: encodeURIComponent(LOG_ACTION.GETLIST),
      logObject: encodeURIComponent(LOG_OBJECT.NDT_DAUTU),
    }
    return this.serviceInvoker.getwithHeaders(request, AppSettings.API_ADDRESS + Api.LaiThuongGetListByUser, headers)
  }
  DashboardGetThongTinQuanLy(request: any): Observable<any> {
    let headers = {
      logAction: encodeURIComponent(LOG_ACTION.GETLIST),
      logObject: encodeURIComponent(LOG_OBJECT.NDT_DAUTU),
    }
    return this.serviceInvoker.getwithHeaders(request, AppSettings.API_ADDRESS + Api.DashboardGetThongTinQuanLy, headers)
  }
}